import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-visionactions',
  templateUrl: './visionactions.component.html',
  styleUrls: ['./visionactions.component.css']
})
export class VisionactionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
