// IMPORTS NECESARIOS
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from "@angular/router";

// IMPORTAR COMPONENTES
import { LoginComponent } from "./components/login/login.component";
import { RegisterComponent } from "./components/register/register.component";
import { HomeComponent } from './components/home/home.component';
import { ErrorComponent } from './components/error/error.component';
import { UserEditComponent } from "./components/user-edit/user-edit.component";
import { CategoryNewComponent } from "./components/category-new/category-new.component";
import { PostNewComponent } from "./components/post-new/post-new.component";
import { PostDetailComponent } from "./components/post-detail/post-detail.component";
import { PostEditComponent } from "./components/post-edit/post-edit.component";
import { CategoryDetailComponent } from "./components/category-detail/category-detail.component";
import { ProfileComponent } from "./components/profile/profile.component";

import { IdentityGuard } from "./services/identity.guard";
import { LegaleinicioComponent } from './components/legale/legaleinicio/legaleinicio.component';
import { LegalesobrenosotrosComponent } from './components/legale/legalesobrenosotros/legalesobrenosotros.component';
import { LegaleserviciosComponent } from './components/legale/legaleservicios/legaleservicios.component';
import { LegalecontactoComponent } from './components/legale/legalecontacto/legalecontacto.component';
import { AcercahomeComponent } from './components/legale/acerca/acercahome/acercahome.component';
import { AbogadoshomeComponent } from './components/legale/abogados/abogadoshome/abogadoshome.component';
import { BienvenidohomeComponent } from './components/legale/bienvenido/bienvenidohome/bienvenidohome.component';
import { CapacidadeshomeComponent } from './components/legale/capacidades/capacidadeshome/capacidadeshome.component';
import { DescPhomeComponent } from './components/legale/descpost/desc-phome/desc-phome.component';
import { AreasPhomeComponent } from './components/legale/areaspracticas/areas-phome/areas-phome.component';
import { NosotroshomeComponent } from './components/legale/nosotros/nosotroshome/nosotroshome.component';
import { MisionhomeComponent } from './components/legale/mision/misionhome/misionhome.component';
import { VisionhomeComponent } from './components/legale/vision/visionhome/visionhome.component';
import { EmailhomeComponent } from './components/legale/correoelectronico/emailhome/emailhome.component';
import { DireccionhomeComponent } from './components/legale/direccíon/direccionhome/direccionhome.component';
import { Horario1homeComponent } from './components/legale/horario1/horario1home/horario1home.component';
import { Horario2homeComponent } from './components/legale/horario2/horario2home/horario2home.component';
import { AbogadosactionsComponent } from './components/legale/abogados/abogadosactions/abogadosactions.component';



const appRoutes: Routes = [
    {path: '', component: HomeComponent},
    {path: 'inicio', component: HomeComponent},
    {path: 'login', component: LoginComponent},
    {path: 'logout/:sure', component: LoginComponent},
    {path: 'registro', component: RegisterComponent},
    {path: 'ajustes', component: UserEditComponent, canActivate: [IdentityGuard]},
    {path: 'crear-categoria', component: CategoryNewComponent, canActivate: [IdentityGuard]},
    {path: 'crear-entrada', component: PostNewComponent, canActivate: [IdentityGuard]},
    {path: 'entrada/:id', component: PostDetailComponent},
    {path: 'editar-entrada/:id', component: PostEditComponent, canActivate: [IdentityGuard]},
    {path: 'categoria/:id', component: CategoryDetailComponent},
    {path: 'perfil/:id', component: ProfileComponent, canActivate: [IdentityGuard]},

    {path: 'legaleinicio', component: LegaleinicioComponent, canActivate: [IdentityGuard]},
    {path: 'legalesobrenosotros', component: LegalesobrenosotrosComponent, canActivate: [IdentityGuard]},
    {path: 'legaleservicios', component: LegaleserviciosComponent, canActivate: [IdentityGuard]},
    {path: 'legalecontacto', component: LegalecontactoComponent, canActivate: [IdentityGuard]},

    // Inicio Legale
    {path: 'acerca', component: AcercahomeComponent, canActivate: [IdentityGuard]},
    {path: 'abogados', component: AbogadoshomeComponent, canActivate: [IdentityGuard]},
    {path: 'bienvenido', component: BienvenidohomeComponent, canActivate: [IdentityGuard]},
    {path: 'capacidades', component: CapacidadeshomeComponent, canActivate: [IdentityGuard]},
    {path: 'descPost', component: DescPhomeComponent, canActivate: [IdentityGuard]},

    {path: 'accionLawyer', component: AbogadosactionsComponent, canActivate: [IdentityGuard]},

    // Servicios Legale
    {path: 'areaspracticas', component: AreasPhomeComponent, canActivate: [IdentityGuard]},
    {path: 'nosotros', component: NosotroshomeComponent, canActivate: [IdentityGuard]},
    {path: 'mision', component: MisionhomeComponent, canActivate: [IdentityGuard]},
    {path: 'vision', component: VisionhomeComponent, canActivate: [IdentityGuard]},

    // Contacto Legale
    {path: 'correo', component: EmailhomeComponent, canActivate: [IdentityGuard]},
    {path: 'direccion', component: DireccionhomeComponent, canActivate: [IdentityGuard]},
    {path: 'horario1', component: Horario1homeComponent, canActivate: [IdentityGuard]},
    {path: 'horario2', component: Horario2homeComponent, canActivate: [IdentityGuard]},


    {path: '**', component: ErrorComponent}
];

// EXPORTAR CONFIGURACIÓN
export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders<any> = RouterModule.forRoot(appRoutes);
