import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-horario1home',
  templateUrl: './horario1home.component.html',
  styleUrls: ['./horario1home.component.css']
})
export class Horario1homeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
