import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { routing, appRoutingProviders } from "./app.routing";
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { AngularFileUploaderModule } from "angular-file-uploader";

import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { HomeComponent } from './components/home/home.component';
import { ErrorComponent } from './components/error/error.component';
import { UserEditComponent } from './components/user-edit/user-edit.component';
import { CategoryNewComponent } from './components/category-new/category-new.component';
import { PostNewComponent } from './components/post-new/post-new.component';
import { PostDetailComponent } from './components/post-detail/post-detail.component';
import { PostEditComponent } from './components/post-edit/post-edit.component';
import { CategoryDetailComponent } from './components/category-detail/category-detail.component';

import { UserService } from "./services/user.service";
import { IdentityGuard } from "./services/identity.guard";
import { User } from './models/user';
import { ProfileComponent } from './components/profile/profile.component';
import { AbogadoshomeComponent } from './components/legale/abogados/abogadoshome/abogadoshome.component';
import { AbogadosactionsComponent } from './components/legale/abogados/abogadosactions/abogadosactions.component';
import { AcercahomeComponent } from './components/legale/acerca/acercahome/acercahome.component';
import { AcercaactionsComponent } from './components/legale/acerca/acercaactions/acercaactions.component';
import { AreasPhomeComponent } from './components/legale/areaspracticas/areas-phome/areas-phome.component';
import { AreasPactionsComponent } from './components/legale/areaspracticas/areas-pactions/areas-pactions.component';
import { BienvenidohomeComponent } from './components/legale/bienvenido/bienvenidohome/bienvenidohome.component';
import { BienvenidoactionsComponent } from './components/legale/bienvenido/bienvenidoactions/bienvenidoactions.component';
import { CapacidadeshomeComponent } from './components/legale/capacidades/capacidadeshome/capacidadeshome.component';
import { CapacidadesactionsComponent } from './components/legale/capacidades/capacidadesactions/capacidadesactions.component';
import { EmailhomeComponent } from './components/legale/correoelectronico/emailhome/emailhome.component';
import { EmailactionsComponent } from './components/legale/correoelectronico/emailactions/emailactions.component';
import { DescPhomeComponent } from './components/legale/descpost/desc-phome/desc-phome.component';
import { DescPactionsComponent } from './components/legale/descpost/desc-pactions/desc-pactions.component';
import { DireccionhomeComponent } from './components/legale/direccíon/direccionhome/direccionhome.component';
import { DireccionactionsComponent } from './components/legale/direccíon/direccionactions/direccionactions.component';
import { Horario1homeComponent } from './components/legale/horario1/horario1home/horario1home.component';
import { Horario1actionsComponent } from './components/legale/horario1/horario1actions/horario1actions.component';
import { Horario2homeComponent } from './components/legale/horario2/horario2home/horario2home.component';
import { Horario2actionsComponent } from './components/legale/horario2/horario2actions/horario2actions.component';
import { MisionhomeComponent } from './components/legale/mision/misionhome/misionhome.component';
import { MisionactionsComponent } from './components/legale/mision/misionactions/misionactions.component';
import { NosotroshomeComponent } from './components/legale/nosotros/nosotroshome/nosotroshome.component';
import { NosotrosactionsComponent } from './components/legale/nosotros/nosotrosactions/nosotrosactions.component';
import { NosotrosCardshomeComponent } from './components/legale/nosotroscards/nosotros-cardshome/nosotros-cardshome.component';
import { NosotrosCardsactionsComponent } from './components/legale/nosotroscards/nosotros-cardsactions/nosotros-cardsactions.component';
import { NuestrosAbogadoshomeComponent } from './components/legale/nuestrosabogados/nuestros-abogadoshome/nuestros-abogadoshome.component';
import { NuestrosAbogadosactionsComponent } from './components/legale/nuestrosabogados/nuestros-abogadosactions/nuestros-abogadosactions.component';
import { ObjetivoHhomeComponent } from './components/legale/objetivohome/objetivo-hhome/objetivo-hhome.component';
import { ObjetivoHactionsComponent } from './components/legale/objetivohome/objetivo-hactions/objetivo-hactions.component';
import { QdicenhomeComponent } from './components/legale/quedicen/qdicenhome/qdicenhome.component';
import { QdicenactionsComponent } from './components/legale/quedicen/qdicenactions/qdicenactions.component';
import { TelefonohomeComponent } from './components/legale/telefono/telefonohome/telefonohome.component';
import { TelefonoactionsComponent } from './components/legale/telefono/telefonoactions/telefonoactions.component';
import { VisionhomeComponent } from './components/legale/vision/visionhome/visionhome.component';
import { VisionactionsComponent } from './components/legale/vision/visionactions/visionactions.component';
import { LegaleinicioComponent } from './components/legale/legaleinicio/legaleinicio.component';
import { LegalesobrenosotrosComponent } from './components/legale/legalesobrenosotros/legalesobrenosotros.component';
import { LegaleserviciosComponent } from './components/legale/legaleservicios/legaleservicios.component';
import { LegalecontactoComponent } from './components/legale/legalecontacto/legalecontacto.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    ErrorComponent,
    UserEditComponent,
    CategoryNewComponent,
    PostNewComponent,
    PostDetailComponent,
    PostEditComponent,
    CategoryDetailComponent,
    AbogadoshomeComponent,
    AbogadosactionsComponent,
    AcercahomeComponent,
    AcercaactionsComponent,
    AreasPhomeComponent,
    AreasPactionsComponent,
    BienvenidohomeComponent,
    BienvenidoactionsComponent,
    CapacidadeshomeComponent,
    CapacidadesactionsComponent,
    EmailhomeComponent,
    EmailactionsComponent,
    DescPhomeComponent,
    DescPactionsComponent,
    DireccionhomeComponent,
    DireccionactionsComponent,
    Horario1homeComponent,
    Horario1actionsComponent,
    Horario2homeComponent,
    Horario2actionsComponent,
    MisionhomeComponent,
    MisionactionsComponent,
    NosotroshomeComponent,
    NosotrosactionsComponent,
    NosotrosCardshomeComponent,
    NosotrosCardsactionsComponent,
    NuestrosAbogadoshomeComponent,
    NuestrosAbogadosactionsComponent,
    ObjetivoHhomeComponent,
    ObjetivoHactionsComponent,
    QdicenhomeComponent,
    QdicenactionsComponent,
    TelefonohomeComponent,
    TelefonoactionsComponent,
    VisionhomeComponent,
    VisionactionsComponent,
    LegaleinicioComponent,
    LegalesobrenosotrosComponent,
    LegaleserviciosComponent,
    LegalecontactoComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    routing,
    AngularFileUploaderModule
  ],
  providers: [
    appRoutingProviders,
    IdentityGuard,
    UserService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
