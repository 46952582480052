<div class="col-md-12" *ngIf="!post">
    Cargando...
</div>

<div class="col-md-12" *ngIf="post">
    <h1>
        {{post.title}}
    </h1>
    <p>
        Categoria: <b>{{post['category'].name}}</b> - Autor:

        <a [routerLink]="['/perfil', post.user.id]">
            {{post['user'].name}}
            {{post['user'].surname}}
        </a>
    </p>
    <hr>
    <h3 [innerHTML]="post.title">
    </h3>
    <p [innerHTML]="post.descripcion">
    </p>
    <p [innerHTML]="post.content">
    </p>
    <p [innerHTML]="post.content2">
    </p>
    <p [innerHTML]="post.content3">
    </p>
    <p [innerHTML]="post.content4">
    </p>
    <p [innerHTML]="post.content5">
    </p>
    <p *ngIf="video1">
      <iframe width="560" height="315" [src]="getVideoIframe(post.video1)" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </p>
    <p *ngIf="video2">
      <iframe width="560" height="315" [src]="getVideoIframe(post.video2)" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </p>

</div>
<br>
