<div class="col-md-12 mt-3">
    <h2>
        {{page_title}}
    </h2>
    <p *ngIf="!is_edit">
        Añade un nuevo artículo al blog
    </p>
    <p *ngIf="is_edit && post">
        Modifica el post {{post.title}}
    </p>
    <hr>

    <!-- Mensajes de alerta -->
    <div class="alert alert-success col-md-5 mt-3" *ngIf="status == 'success'">
        Los datos se han guardado correctamente...!!
    </div>

    <div class="alert alert-danger col-md-5" *ngIf="status == 'error'">
        Los datos no se han guardado
    </div>

    <!-- Formulario de crear entrada-->
    <form class="col-md-5 ml-0 pl-0" #postNew="ngForm" (ngSubmit)="onSubmit(postNew)">

      <div class="form-group">
        <label for="category_id">¿Qué quieres crear?</label>
        <select name="category_id" #category="ngModel" [(ngModel)]="post.category_id" class="form-control">
            <option *ngFor="let category of categories" value="{{category.id}}">
                {{category.name}}
            </option>
        </select>
      </div>

        <div class="form-group">
            <label for="title">Título blog o nombre completo de abogado</label>
            <input type="text" name="title" #title="ngModel" [(ngModel)]="post.title" class="form-control" required />
            <small *ngIf="!title.valid && title.touched" class="invalid_feedback d-block">
                El título no es válido
            </small>
        </div>

        <div class="form-group">
          <label for="descripcion">Descripción de abogado</label>
          <p style="font-size: 10px;">Debe ser un título corto - Ejemplo: Abogado asociado</p>
          <input type="text" name="descripcion" #descripcion="ngModel" [(ngModel)]="post.descripcion" class="form-control" />
          <small *ngIf="!descripcion.valid && descripcion.touched" class="invalid_feedback d-block">
              El título no es válido
          </small>
        </div>

        <div class="form-group">
            <label for="content">Contenido - Parrafo 1</label>
            <textarea name="content" #content="ngModel" [(ngModel)]="post.content" class="form-control" required></textarea>
            <small *ngIf="!content.valid && content.touched" class="invalid_feedback d-block">
                El contenido no es válido
            </small>
        </div>

        <div class="form-group">
          <label for="content2">Contenido - Parrafo 2</label>
          <textarea name="content2" #content2="ngModel" [(ngModel)]="post.content2" class="form-control"></textarea>
          <small *ngIf="!content2.valid && content2.touched" class="invalid_feedback d-block">
              El contenido no es válido
          </small>
        </div>

        <div class="form-group">
          <label for="content3">Contenido - Parrafo 3</label>
          <textarea name="content3" #content3="ngModel" [(ngModel)]="post.content3" class="form-control"></textarea>
          <small *ngIf="!content3.valid && content3.touched" class="invalid_feedback d-block">
              El contenido no es válido
          </small>
        </div>

        <div class="form-group">
          <label for="content4">Contenido - Parrafo 4</label>
          <textarea name="content4" #content4="ngModel" [(ngModel)]="post.content4" class="form-control"></textarea>
          <small *ngIf="!content4.valid && content4.touched" class="invalid_feedback d-block">
              El contenido no es válido
          </small>
        </div>

        <div class="form-group">
          <label for="content5">Contenido - Parrafo 5</label>
          <textarea name="content5" #content5="ngModel" [(ngModel)]="post.content5" class="form-control"></textarea>
          <small *ngIf="!content5.valid && content5.touched" class="invalid_feedback d-block">
              El contenido no es válido
          </small>
        </div>

        <div class="form-group">
          <label for="video1">Enlace - Video 1</label>
          <input type="text" name="video1" #video1="ngModel" [(ngModel)]="post.video1" class="form-control" />
          <small *ngIf="!video1.valid && video1.touched" class="invalid_feedback d-block">
              El título no es válido
          </small>
        </div>

        <div class="form-group">
          <label for="video2">Enlace - Video 2</label>
          <input type="text" name="video2" #video2="ngModel" [(ngModel)]="post.video2" class="form-control" />
          <small *ngIf="!video2.valid && video2.touched" class="invalid_feedback d-block">
              El título no es válido
          </small>
        </div>

        <div class="form-group">
            <label for="image">Imagen</label>

            <div *ngIf="is_edit && post.image">
                <img src="{{url + 'user/avatar/' + post.image}}" class="avatar_edit">
            </div>

            <angular-file-uploader
                [config]="afuConfig"
                [resetUpload]="true"
                (ApiResponse)="avatarUpload($event)">
            </angular-file-uploader>
        </div>

        <input type="submit" value="Guardar" class="btn btn-success" [disabled]="postNew.invalid">
    </form>

    <br>
</div>
