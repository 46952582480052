<div class="loading" *ngIf="!posts">
    Cargando...
</div>

<div class="container-fluid mt-3" *ngIf="posts">

    <div class="card-columns">

        <article class="card w-100" *ngFor="let post of posts">
            
            <div class="card-header">
                <h3>
                   <a [routerLink]="['/inicio']">
                        {{post.title}}
                    </a>
                </h3>
            </div>

            <div class="card-body">

                <!--Imgen-->
                <div class="post-image w-100" *ngIf="post.image">
                    <img src="{{ url + 'post/image/' + post.image}}" class="w-100">
                </div>

                <div class="post-image w-100" *ngIf="!post.image">
                    <img src="https://via.placeholder.com/400x250" class="w-100">
                </div>


                <!--Botones de acción-->

                <div class="post-buttons mt-3">
                    <a [routerLink]="['/entrada', post.id]" class="btn btn-success mr-2">Leer</a>

                    <span *ngIf="identity && identity.sub == post.user_id">
                        <a [routerLink]="['/editar-entrada', post.id]" class="btn btn-warning mr-2">Editar</a>

                        <!-- Button trigger modal -->
                            <button type="button" class="btn btn-danger" data-toggle="modal" [attr.data-target]="'#ModalBorrar' + post.id">
                                Borrar
                            </button>
                            
                            <!-- Modal -->
                            <div class="modal fade" id="{{ 'ModalBorrar'+post.id }}" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">¿Deseas eliminar el siguiente post? => <b>{{post.title}}</b></h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    </div>
                                    <div class="modal-body">
                                    {{post.content}}
                                    </div>
                                    <div class="modal-footer">
                                        <a (click)="deletePost(post.id)" class="btn btn-danger" data-dismiss="modal">Borrar</a>
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Regresar</button>
                                    </div>
                                </div>
                                </div>
                            </div>

                    </span>
                </div>

            </div>

        </article>

    </div>

</div>
