<!--CONTENEDOR PRINCIPAL-->
<div class="container">
  <br>

  <!--BARRA DE NAVEGACIÓN-->
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
      <a class="navbar-brand" href="#">
        <img src="../assets/images/legale_blanco.png" width="30" height="30" class="d-inline-block align-top" alt="">
        ADMIN - Legale</a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item active">
            <a class="nav-link" [routerLink]="['/inicio']">Inicio <span class="sr-only">(current)</span></a>
          </li>
          <li class="nav-item dropdown">
            <a *ngIf="identity" class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Ediciones
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <!-- <a class="dropdown-item" [routerLink]="['/legaleinicio']">Inicio</a>
              <a class="dropdown-item" [routerLink]="['/legalesobrenosotros']">Sobre nosotros</a>
              <a class="dropdown-item" [routerLink]="['/legaleservicios']">Servicios</a>
              <a class="dropdown-item" [routerLink]="['/legalecontacto']">Contacto</a> -->
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" [routerLink]="['/crear-entrada']">Crear post</a>
            </div>
          </li>
        </ul>

        <ul class="navbar-nav navbar-right" *ngIf="!identity">
          <li class="nav-item active">
            <a class="nav-link" [routerLink]="['/login']">Login</a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" [routerLink]="['/registro']">Registro</a>
          </li> -->
        </ul>

        <ul class="navbar-nav navbar-right" *ngIf="identity && identity.name">
          <li *ngIf="identity && identity.image" class="nav-item">
            <img src="{{url + 'user/avatar/' + identity.image}}" class="avatar_nav mt-0">
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {{ identity.name + ' ' + identity.surname}}
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" [routerLink]="['/perfil', identity.sub]">Mi perfil</a>
              <a class="dropdown-item" [routerLink]="['/ajustes']">Ajustes</a>
              <a class="dropdown-item" [routerLink]="['/logout/1']">Salir</a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  <!--FIN BARRA DE NAVEGACIÓN-->

<!--CONTENIDO-->
<router-outlet></router-outlet>
<!--FIN CONTENIDO-->


<footer class="text-center">
  <hr class="center">
    <p class="text-primary">Admin Legale Abogados Cj &copy;</p>
  <hr>
</footer>

</div>
<!--FIN CONTENEDOR PRINCIPAL-->
