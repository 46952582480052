import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nosotroshome',
  templateUrl: './nosotroshome.component.html',
  styleUrls: ['./nosotroshome.component.css']
})
export class NosotroshomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
