import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-misionactions',
  templateUrl: './misionactions.component.html',
  styleUrls: ['./misionactions.component.css']
})
export class MisionactionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
