import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-telefonohome',
  templateUrl: './telefonohome.component.html',
  styleUrls: ['./telefonohome.component.css']
})
export class TelefonohomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
