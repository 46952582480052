import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-direccionactions',
  templateUrl: './direccionactions.component.html',
  styleUrls: ['./direccionactions.component.css']
})
export class DireccionactionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
