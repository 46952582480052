import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-objetivo-hhome',
  templateUrl: './objetivo-hhome.component.html',
  styleUrls: ['./objetivo-hhome.component.css']
})
export class ObjetivoHhomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
