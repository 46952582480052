<div class="col-md-12 mt-3">
    <h2>
        {{page_title}}
    </h2>

    <p *ngIf="status != 'success'">
        Registrate en nuestra plataforma para crear nuevas entradas y mucho más!!
    </p>

    <div class="alert alert-success col-md-5 mt-3" *ngIf="status == 'success'">
        El registro se ha creado correctamente, <a [routerLink]="['/login']">Inicia sesión</a>
    </div>

    <div class="alert alert-danger col-md-5" *ngIf="status == 'error'">
        El registro no se ha completado.
    </div>

    <!--Formulario de registro-->
    <form class="col-md-5 ml-0 pl-0" #registerForm="ngForm" (ngSubmit)="onSubmit(registerForm)">
        <div class="form-group">
            <label for="name">Nombre</label>
            <input type="text" name="name" class="form-control" #name="ngModel" [(ngModel)]="user.name" required pattern="[a-z A-Z]+">
            <small *ngIf="!name.valid && name.touched" class="invalid-feedback d-block">El nombre no es válido</small>
        </div>

        <div class="form-group">
            <label for="surname">Apellidos</label>
            <input type="text" name="surname" class="form-control" #surname="ngModel" [(ngModel)]="user.surname" required pattern="[a-z A-Z]+">
            <small *ngIf="!surname.valid && surname.touched" class="invalid-feedback d-block">Los apellidos no son válidos</small>
        </div>

        <div class="form-group">
            <label for="email">Email</label>
            <input type="email" name="email" class="form-control" #email="ngModel" [(ngModel)]="user.email" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$">
            <small *ngIf="!email.valid && email.touched" class="invalid-feedback d-block">El email no es válido</small>
        </div>

        <div class="form-group">
            <label for="password">Contraseña</label>
            <input type="password" name="password" class="form-control" #password="ngModel" [(ngModel)]="user.password" required>
            <small *ngIf="!password.valid && password.touched" class="invalid-feedback d-block">La contraseña no es válida</small>
        </div>

        <input type="submit" value="Registrar" class="btn btn-success" [disabled]="registerForm.invalid">
    </form>
</div>
