import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-objetivo-hactions',
  templateUrl: './objetivo-hactions.component.html',
  styleUrls: ['./objetivo-hactions.component.css']
})
export class ObjetivoHactionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
