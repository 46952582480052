<div class="col-md-12 mt-3">
    <h2>
        {{page_title}}
    </h2>

    <p *ngIf="status != 'success'">
        Crea una nueva categoria para el blog
    </p>
    <hr>
    
    <div class="alert alert-success col-md-5 mt-3" *ngIf="status == 'success'">
        La categoria se ha creado correctamente...!!
    </div>

    <div class="alert alert-danger col-md-5" *ngIf="status == 'error'">
        La categoria no se ha guardado
    </div>

    <!--Formulario de crear categoria-->

    <form #categoryForm="ngForm" (ngSubmit)="onSubmit(categoryForm)" class="col-md-5">

        <div class="form-group">
            <label for="name">Nombre</label>
            <input type="text" name="name" class="form-control" #name="ngModel" [(ngModel)]="category.name" required />
            <small *ngIf="!name.valid && name.touched" class="invalid-feedback d-block">
                El nombre de la categoría no es válido
            </small>
        </div>

        <input type="submit" value="Guardar" class="btn btn-success" [disabled]="categoryForm.invalid" />

    </form>

    <br>
    
</div>
