import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-desc-phome',
  templateUrl: './desc-phome.component.html',
  styleUrls: ['./desc-phome.component.css']
})
export class DescPhomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
