import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-desc-pactions',
  templateUrl: './desc-pactions.component.html',
  styleUrls: ['./desc-pactions.component.css']
})
export class DescPactionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
