import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-legalecontacto',
  templateUrl: './legalecontacto.component.html',
  styleUrls: ['./legalecontacto.component.css']
})
export class LegalecontactoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
