<div class="col-md-12 mt-3">
    <h2>
        {{page_title}}
    </h2>

    <p *ngIf="status != 'success'">
        Modifica tus datos personales de usuario.
    </p>
    <hr>

    <div class="alert alert-success col-md-5 mt-3" *ngIf="status == 'success'">
        Los datos se han guardado correctamente...!!
    </div>

    <div class="alert alert-danger col-md-5" *ngIf="status == 'error'">
        Los datos no se han guardado
    </div>

    <!--Formulario de ajustes-->
    <form class="col-md-5 ml-0 pl-0" #userEditForm="ngForm" (ngSubmit)="onSubmit(userEditForm)">
        <div class="form-group">
            <label for="name">Nombres</label>
            <input type="text" name="name" class="form-control" #name="ngModel" [(ngModel)]="user.name" required pattern="[a-z A-Z]+">
            <small *ngIf="!name.valid && name.touched" class="invalid-feedback d-block">El nombre no es válido</small>
        </div>

        <div class="form-group">
            <label for="surname">Apellidos</label>
            <input type="text" name="surname" class="form-control" #surname="ngModel" [(ngModel)]="user.surname" required pattern="[a-z A-Z]+">
            <small *ngIf="!surname.valid && surname.touched" class="invalid-feedback d-block">Los apellidos no son válidos</small>
        </div>

        <div class="form-group">
            <label for="email">Email</label>
            <input type="email" name="email" class="form-control" #email="ngModel" [(ngModel)]="user.email" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$">
            <small *ngIf="!email.valid && email.touched" class="invalid-feedback d-block">El email no es válido</small>
        </div>

        <div class="form-group">
            <label for="description">Descripción</label>
            <textarea name="description" class="form-control" #description="ngModel" [(ngModel)]="user.description"></textarea>
        </div>

        <div class="form-group">
            <label for="image">Avatar</label>

            <div *ngIf="identity && identity.image">
                <img src="{{url + 'user/avatar/' + identity.image}}" class="avatar_edit">
            </div>

            <angular-file-uploader #fileUpload1
                [config]="afuConfig" 
                [resetUpload]="true"
                (ApiResponse)="avatarUpload($event)">
            </angular-file-uploader>
        </div>

        <input type="submit" value="Guardar" class="btn btn-success" [disabled]="userEditForm.invalid">
    </form>
</div>