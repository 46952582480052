import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nosotros-cardshome',
  templateUrl: './nosotros-cardshome.component.html',
  styleUrls: ['./nosotros-cardshome.component.css']
})
export class NosotrosCardshomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
