import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nuestros-abogadosactions',
  templateUrl: './nuestros-abogadosactions.component.html',
  styleUrls: ['./nuestros-abogadosactions.component.css']
})
export class NuestrosAbogadosactionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
