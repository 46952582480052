import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-areas-pactions',
  templateUrl: './areas-pactions.component.html',
  styleUrls: ['./areas-pactions.component.css']
})
export class AreasPactionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
