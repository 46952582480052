import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bienvenidoactions',
  templateUrl: './bienvenidoactions.component.html',
  styleUrls: ['./bienvenidoactions.component.css']
})
export class BienvenidoactionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
