import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-horario2actions',
  templateUrl: './horario2actions.component.html',
  styleUrls: ['./horario2actions.component.css']
})
export class Horario2actionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
