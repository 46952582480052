import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { Post } from "../../models/post";
import { PostService } from "../../services/post.service";
import { UserService } from "../../services/user.service";
import { global } from "../../services/global";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  providers: [UserService, PostService]
})
export class ProfileComponent implements OnInit {

  public page_title: string;
  public url;
  public posts: Array<Post>;
  public status: string;
  public identity;
  public token;

  constructor(
    private _postService: PostService,
    private _userService: UserService,
    private _router: Router,
    private _route: ActivatedRoute
  ) {
    this.url = global.url;
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
   }

  ngOnInit(): void {
    this.getProfile();
  }

  getProfile(){
    this._route.params.subscribe(
      params =>{
      let userId = +params['id'];
      this.getUser(userId);
      this.getPosts(userId);
      });
  }

  getUser(userId){
    this.getProfile();
  }

  getPosts(userId){
    this._userService.getPosts(userId).subscribe(
      response => {
        if (response.status == 'success') {
          this.posts = response.posts;
        }
      },
      error => {
        console.log(error);
      }
    );
  } 

  deletePost(id){
    this._postService.delete(this.token, id).subscribe(
      response =>{
        this.getProfile();
      },
      error =>{
        console.log(error);
      }
    );
  }

}

