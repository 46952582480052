import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-capacidadeshome',
  templateUrl: './capacidadeshome.component.html',
  styleUrls: ['./capacidadeshome.component.css']
})
export class CapacidadeshomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
