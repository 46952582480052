<div class="col-md-12 mt-3">

    <p>
        Identifícate en la plataforma para comenzar...!!
    </p>

    <form class="col-md-5 ml-0 pl-0" #loginForm="ngForm" (ngSubmit)="onSubmit(loginForm)">

        <div class="form-group">
            <label for="email">Email</label>
            <input type="email" name="email" class="form-control" #email="ngModel" [(ngModel)]="user.email" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$">
            <small *ngIf="!email.valid && email.touched" class="invalid-feedback d-block">El email no es válido</small>
        </div>

        <div class="form-group">
            <label for="password">Contraseña</label>
            <input type="password" name="password" class="form-control" #password="ngModel" [(ngModel)]="user.password" required>
            <small *ngIf="!password.valid && password.touched" class="invalid-feedback d-block">La contraseña no es válida</small>
        </div>

        <input type="submit" value="Iniciar sesión" class="btn btn-success" [disabled]="loginForm.invalid">
    </form>
</div>
